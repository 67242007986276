import { addDays, eachDayOfInterval, getDay, isToday, differenceInSeconds, isValid } from 'date-fns';
import { GetDeliveryDaysQuery } from '../../generated/graphql';

const dayOfWeekMap = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
};

export const getUpcomingDeliveryDates = (deliveryDays:string[], now: string, timeWindow = 14): Date[] => {
    const today = new Date(now);
    const in2Weeks = addDays(today, timeWindow);
    const days = eachDayOfInterval({
        start: today,
        end: in2Weeks
    }) as Date[];
    let deliveryDates = days.filter(day => {
        const dayName = dayOfWeekMap[getDay(day)];
        return deliveryDays.includes(dayName); 
    });
    return deliveryDates;
}

export const getNextDeliveryDate = (deliveryDaysData: GetDeliveryDaysQuery, excludeToday: boolean = false)=>{
    let daysOfWeek: string[] = [];
    let upcomingDeliveryDates: Date[] = [];
    if (deliveryDaysData) {
        daysOfWeek = deliveryDaysData.delivery_area_day.map((day: any) => day.day_of_week);
        upcomingDeliveryDates = getUpcomingDeliveryDates(daysOfWeek, new Date().toISOString(), 7);
    }
    if (upcomingDeliveryDates.length > 0){
        const first = upcomingDeliveryDates[0];
        if (excludeToday && isToday(first)){
            if (upcomingDeliveryDates.length < 2) return null;
            return upcomingDeliveryDates[1]; //next available date is today, for new orders we want to skip today.
        }else{
            return first;
        }
    }
    return null; 
}

export const getNextDeliveryDateForPostcode = (deliveryDaysData: GetDeliveryDaysQuery, postcode: string)=>{
    if (!postcode) return null;
    const daysForPostcode = deliveryDaysData.delivery_area_day.filter(deliveryDay => ( deliveryDay?.postcode === postcode));
    let daysOfWeek: string[] = [];
    let upcomingDeliveryDates: Date[] = [];
    daysOfWeek = daysForPostcode.map(day => day.day_of_week);
    upcomingDeliveryDates = getUpcomingDeliveryDates(daysOfWeek, new Date().toISOString(), 7); //get available delivery dates in next 7 days
    if (upcomingDeliveryDates.length > 0){
        return upcomingDeliveryDates[0];
    }else{
        return null;
    }
}

export const formatDuration = (start: Date, end: Date, maxUnit = 'd') => {
    if (!['s','m','h','d'].includes(maxUnit)) throw Error('maxUnit needs to be s/m/h/d');
    if (!isValid(start)) throw Error('Invalid start date');
    if (!isValid(end)) throw Error('Invalid end date');
    const diffTime = differenceInSeconds(start, end);
    if (!diffTime) return '00:00'; // divide by 0 protection
    const seconds = Math.abs(Math.floor(diffTime % 60)).toString();
    const minutes = Math.abs(Math.floor(diffTime / 60) % 60).toString();
    const hours = Math.abs(Math.floor(diffTime / 60 / 60) % 60).toString();
    const days = Math.abs(Math.floor(diffTime / 60 / 60 / 24)).toString();
    let durationString = seconds.length < 2 ? 0 + seconds : seconds;
    if (['m','h','d'].includes(maxUnit)){
        durationString = `${minutes.length < 2 ? 0 + minutes : minutes}:${durationString}`;
    }
    if (['h','d'].includes(maxUnit)){
        durationString = `${hours.length < 2 ? 0 + hours : hours}:${durationString}`;
    }
    if (['d'].includes(maxUnit)){
        durationString = `${days.length < 2 ? 0 + days : days}:${durationString}`;
    }

    return durationString;
};

export const shortDateFormat = 'd MMM';

export const dateAndTimeFormat = 'h:mma d MMM yyyy';