import React, { useContext } from 'react';
import { IonButton, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { paperPlaneOutline, paperPlaneSharp, listOutline, logOutOutline, beakerOutline, hourglassOutline } from 'ionicons/icons';
import './Menu.css';
import StateContext from '../StateContext';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Tanks',
    url: '/tanks',
    iosIcon: beakerOutline,
    mdIcon: beakerOutline
  },
  {
    title: 'Batches',
    url: '/batches',
    iosIcon: listOutline,
    mdIcon: listOutline
  }
];

const Menu: React.FC = () => {
  const location = useLocation();
  const { signOut } = useContext(StateContext);
  const logoutUrl = window.location.protocol + '//' + window.location.host;

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Happy Cow</IonListHeader>
          {/* <IonNote>{user?.name}</IonNote> */}
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" icon={appPage.iosIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
        <IonButton color="light" onClick={() => { signOut() }}>
            <IonIcon slot="start" icon={logOutOutline} />
            <IonLabel>Logout</IonLabel>
          </IonButton>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
