import * as React from 'react';
import { useState, useContext } from 'react';
import { IonGrid, IonRow, IonCol, IonRouterLink, IonButton, IonModal, IonContent, IonIcon } from '@ionic/react';
import { Tank, Batch, Sensor_Reading, Maybe } from '../generated/graphql';
import { useMutation } from '@apollo/react-hooks';
import { startBatchMutation, stopBatchMutation } from '../queries/tanks';
import { insertCrossCheckMutation } from '../queries/batches';
import { StartBatchMutation, StopBatchMutation, InsertCrosscheckMutation } from '../generated/graphql';
import BatchStartChecklist from './BatchStartChecklist';
import StateContext from '../StateContext';
import { play, thermometer, timer, flask, iceCream, snow, checkmarkDone, close } from 'ionicons/icons'
import { InputText } from 'primereact/inputtext'

interface TankTileProps {
    tank: { __typename?: 'tank' }
    & Pick<Tank, 'id' | 'last_seen'>
    & {
        current_batch?: Maybe<(
            { __typename?: 'batch' }
            & Pick<Batch, 'id' | 'stage'>
            & {
                current_temp: Array<(
                    { __typename?: 'sensor_reading' }
                    & Pick<Sensor_Reading, 'product_temp' | 'air_temp' | 'crosscheck_temp' | 'timestamp'>
                )>
            }
        )>
    }
}


const TankTile: React.FC<TankTileProps> = ({ tank }) => {
    let tankBusy = false;
    if (tank.current_batch) {
        if (!['complete', 'cancelled'].includes(tank.current_batch.stage)) {
            tankBusy = true;
        }
    }
    const { user } = useContext(StateContext);
    const [started, setStarted] = useState<boolean>(tankBusy);
    const [checked, setChecked] = useState<boolean>(false)
    const [crosscheckValue, setCrosscheckValue] = useState<number>(null)
    const [showChecklist, setShowChecklist] = useState<boolean>(false);
    const [startBatch] = useMutation<StartBatchMutation>(startBatchMutation);
    const [stopBatch] = useMutation<StopBatchMutation>(stopBatchMutation);
    const [insertCrosscheck] = useMutation<InsertCrosscheckMutation>(insertCrossCheckMutation);

    React.useEffect(() => {
        if (checked) {


        }
    }, [checked])
    const getTileClass = () => {
        switch (tank?.current_batch?.stage) {
            case 'heating':
                return 'batch-tile-heating'
            case 'cooling':
                return 'batch-tile-cooling'
            case 'ice':
                return 'batch-tile-ice'
            case 'maintaining':
                return 'batch-tile-maintaining'
            default:
                return 'batch-tile'
        }
    }

    const onChangeCrosscheck = async (value: number) => {
        setCrosscheckValue(value)
    }

    const onInsertCrosscheck = async (tank) => {
        if (!crosscheckValue) {
            return false
        }
        if (!tank.current_batch) {
            alert('No batch assigned to tank.')
            return false
        }
        try {
            const variables = {
                'batch_id': tank.current_batch.id,
                'product_temp': tank.current_batch.current_temp[0].product_temp,
                'crosscheck_temp': crosscheckValue
            }
            const _insert = await insertCrosscheck({ variables })
            if (_insert) {
                setChecked(true)
            }
        }
        catch (e) {
            console.log(e);
            alert('Failed to insert crosscheck');
        }
    }

    const onStartBatch = async (tank) => {
        if (!window.confirm('Are you sure you want to start tank ' + tank.id)) {
            return false;
        }
        try {
            const variables = {
                'tankId': tank.id,
                'userId': user.uid
            }
            await startBatch({ variables });
            setStarted(true);
            setShowChecklist(false);
        } catch (e) {
            console.log(e);
            alert('Start error');
        }
    }

    const onStopBatch = async () => {
        try {
            const variables = {
                'tankId': tank.id
            }
            await stopBatch({ variables });
            setStarted(false);
        } catch (e) {
            console.log(e);
            alert('Stop error');
        }
    }

    const startStopHandler = async (tankId: number, action: string) => {
        switch (action) {
            case "start":
                // onStartBatch(tank);
                setShowChecklist(true)
                break;
            case "stop":
                onStopBatch();
            default:
                return false
        }
    }

    const stateIcon = (state) => {
        if (started) {
            switch (state) {
                case 'heating':
                    return <IonIcon className="cursor-pointer" size="large" color="primary" icon={thermometer} />
                case 'maintaining':
                    return <IonIcon className="cursor-pointer" size="large" color="primary" icon={flask} />
                case 'cooling':
                    return <IonIcon className="cursor-pointer" size="large" color="primary" icon={iceCream} />
                case 'ice':
                    return <IonIcon className="cursor-pointer" size="large" color="primary" icon={snow} />
                case 'complete':
                    return <IonIcon className="cursor-pointer" size="large" color="primary" icon={checkmarkDone} />
                case 'cancelled':
                    return <IonIcon className="cursor-pointer" size="large" color="primary" icon={close} />
                default:
                    return <IonIcon className="cursor-pointer" size="large" color="primary" icon={timer} />
            }

        } else {
            if (state === 'cancelled') {
                return <IonIcon className="cursor-pointer" size="large" icon={close} color="danger" />
            }
            if (state === 'complete') {
                return <IonIcon className="cursor-pointer" size="large" icon={checkmarkDone} color="success" />
            }
            return <IonIcon className="cursor-pointer" size="large" icon={play} color="primary" />
        }
    }


    return <div className="surface-card shadow-2 p-3 border-round h-full flex flex-row flex-auto justify-content-between">
         <IonModal isOpen={showChecklist}>
            <IonContent className="ion-padding">
                <BatchStartChecklist tankId={tank.id} tank={tank} onStartBatch={onStartBatch} onCancel={() => setShowChecklist(false)}></BatchStartChecklist>
            </IonContent>
        </IonModal>
        <div className="flex flex-column w-6">

            <div className="flex justify-content-between mb-3">
                <div className="flex flex-row align-items-center justify-content-between w-full">

                    <span className="text-500 font-medium">Tank {tank.id}</span>
                    {tank?.current_batch?.id && <IonRouterLink routerLink={`/batch/${tank?.current_batch?.id}`} className="text-500 font-medium text-sm">Batch: {tank.current_batch?.id}</IonRouterLink>}
                </div>

            </div>
            <div className="flex flex-column">
                <div className="flex flex-row justify-content-between">
                    <span className="text-500 text-sm">Last temp: </span>
                    <span className="text-green-500 font-medium">{tank?.current_batch?.current_temp[0]?.product_temp || "--"}°C</span>

                </div>
                <div className="flex flex-row justify-content-between">
                    <span className="text-500 text-sm">Last air: </span>
                    <span className="text-orange-500 font-medium">{tank?.current_batch?.current_temp[0]?.air_temp || "--"}°C</span>

                </div>
                <div className="flex flex-row justify-content-between align-items-center">

                    <label className="text-sm text-500" htmlFor="crosscheck">Crosscheck</label>
                    {!checked ? <><InputText className="text-sm border-none border-bottom-1 border-400 w-2" placeholder="" id="crosscheck" style={{ width: 60 }} onChange={(e) => onChangeCrosscheck(parseFloat(e.target.value))} /><div className="text-xs text-blue-800 font-bold cursor-pointer" onClick={() => onInsertCrosscheck(tank)}>SAVE</div></> : <span className='text-blue-500'>{crosscheckValue}°C</span>}

                </div>

            </div>
        </div>
        <div className="flex h-full "

            onClick={() => startStopHandler(tank.id, started ? 'stop' : 'start')}>
            <div className="flex flex-column align-items-end justify-content-between">
                {stateIcon(tank?.current_batch?.stage)}
                {tank?.current_batch?.stage === 'maintaining' && <Timer start={0} />}
                <div>{tank?.current_batch?.stage}</div>

            </div>
        </div>
    </div>


}

const BatchData: React.FC<TankTileProps> = ({ tank }) => {
    return <><IonRow>
        <IonCol>
            <h4>Product: {tank.current_batch.current_temp[0].product_temp}°C</h4>
        </IonCol>
    </IonRow>
        <IonRow>
            <IonCol>
                <h4>Air: {tank.current_batch.current_temp[0].air_temp}°C</h4>
            </IonCol>
        </IonRow>
    </>
}

const Timer = ({ start }) => {

    const [timer, setTimer] = useState(start)

    React.useEffect(() => {
        const _t = window.setTimeout(() => setTimer(prev => prev + 1), 1000)
        return () => clearTimeout(_t)
    }, [timer])
    return (
        <div>{timer}</div>
    )
}

export default TankTile;