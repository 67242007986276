import React, { useState } from 'react';
import { IonText, IonButton, IonPage, IonContent } from '@ionic/react';
import LoginForm from '../components/LoginForm';
import SignupForm from '../components/SignupForm';
import HcLogo from '../assets/HcLogo';

interface HomeProps{
    firebaseAuth: firebase.auth.Auth
}
const Home:React.FC<HomeProps> = ({firebaseAuth})=>{
    const [form, setForm] = useState('home');

    return <IonPage className="ion-padding">
        <IonContent>
        {form === 'home' && <div className="centred-form home">
            <div className="home-logo-container">
                <HcLogo></HcLogo>
            </div>
            <IonText color="secondary"><h1>Home Delivery Moovement</h1></IonText>
            <IonButton className="medium" color="secondary" onClick={()=>setForm('login')}><span>Log In</span></IonButton>
            <IonButton className="medium" color="secondary" onClick={()=>setForm('signup')}><span>Sign Up</span></IonButton>
        </div>}
        {form === 'login' && <LoginForm firebaseAuth={firebaseAuth} cancelLogin={()=>setForm('home')}></LoginForm>}
        {form === 'signup' && <SignupForm firebaseAuth={firebaseAuth} cancelSignup={()=>setForm('home')}></SignupForm>}
        </IonContent>
    </IonPage>
}

export default Home;