import React from 'react';
import { Batch } from '../generated/graphql';
import {
    IonItem,
    IonGrid,
    IonCol,
    IonIcon,
    IonRow,
    IonRouterLink,
    IonCard,
    IonCardTitle,
    IonCardSubtitle,
    IonCardHeader,
    IonCardContent
} from '@ionic/react';
import { shieldOutline, shieldCheckmarkOutline } from 'ionicons/icons';
import BatchStatusChip from './BatchStatusChip';
import { isBatchAssigned } from '../utils/batches';
import { format } from 'date-fns';
import { ShieldCheckmarkOutline, ShieldOutline } from 'react-ionicons'

const dateFormat = 'h:mm dd/MM/yyyy';

interface BatchListItemProps {
    batch: Partial<Batch>;
    // selectBatch: (batch:Partial<Batch>)=>void;
}
const BatchListItem: React.FC<BatchListItemProps> = ({ batch }) => {
    const assigned = isBatchAssigned(batch);
    const status = assigned ? 'assigned' : 'complete';
    if (status !== 'complete') {
        return null;
    }

    const SUCCESS_COLOR = "#26A104";
    const SUCCESS_ICON = <IonIcon icon={shieldCheckmarkOutline} size="large" style={{ color: SUCCESS_COLOR }} />
    const FAIL_COLOR = "#d93b3b";
    const FAIL_ICON = <IonIcon icon={shieldOutline} size="large" style={{ color: FAIL_COLOR }} />

    return <IonItem routerLink={`/batch/${batch.id}`}>




        <IonCard style={{ width: '100%' }}>
            <IonCardHeader >
                <IonCardTitle color="primary">BATCH: {batch.id}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonCardSubtitle>{format(new Date(batch.started), dateFormat)}</IonCardSubtitle>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        {batch.validated ? SUCCESS_ICON : FAIL_ICON}
                    </div>
                    <div>
                        {batch.batch_error?.text}

                    </div>

                </div>
            </IonCardContent>
        </IonCard>


    </IonItem>

}

export default BatchListItem;