import * as React from "react";

function SvgHcLogo(props) {
  return (
    <svg
      id="HC_logo_svg__Ebene_1"
      data-name="Ebene 1"
      viewBox="0 0 116.29 108.11"
      {...props}
    >
      <defs>
        <style>{".HC_logo_svg__cls-1{fill:#0059a4}"}</style>
      </defs>
      <path
        className="HC_logo_svg__cls-1"
        d="M58.15 108.11a35.62 35.62 0 10-35.63-35.62 35.66 35.66 0 0035.63 35.62zm0-67.2a31.58 31.58 0 11-31.58 31.58 31.61 31.61 0 0131.58-31.58zM13.18 17.79a1.78 1.78 0 00.87-.21 8.18 8.18 0 017 0 2 2 0 002.69-.94 2 2 0 00-.94-2.7 12.16 12.16 0 00-10.47 0 2 2 0 00.88 3.85z"
      />
      <path
        className="HC_logo_svg__cls-1"
        d="M24.47 37.7a2 2 0 002-2 31.66 31.66 0 0163.31 0 2 2 0 002 2 24.49 24.49 0 0024.46-24.47 1.91 1.91 0 00-.16-.78 2 2 0 00-.4-.62 23.6 23.6 0 00-32.08-1.12 35.71 35.71 0 00-50.6-.3A24 24 0 00.59 11.79a2.19 2.19 0 00-.4.62 1.85 1.85 0 00-.16.78A24.49 24.49 0 0024.47 37.7zm87.76-23.62a20.45 20.45 0 01-18.45 19.48 35.63 35.63 0 00-7.49-19.84 19.52 19.52 0 0125.94.36zm-82-.65a35.45 35.45 0 00-7.74 20.13A20.46 20.46 0 014.06 14.08a20 20 0 0126.19-.65z"
      />
      <path
        className="HC_logo_svg__cls-1"
        d="M49.1 25.39a2.33 2.33 0 11-4.66 0 2 2 0 00-4 0 6.38 6.38 0 1012.76 0 2 2 0 00-4 0zM71.85 25.39a2.33 2.33 0 11-4.66 0 2 2 0 00-4 0 6.38 6.38 0 1012.76 0 2 2 0 00-4.05 0zM95.57 17.58a8.18 8.18 0 017 0 2 2 0 002.69-.94 2 2 0 00-.94-2.7 12.16 12.16 0 00-10.47 0 2 2 0 00.88 3.85 1.78 1.78 0 00.84-.21zM42.41 61.89a2 2 0 002-2 2.33 2.33 0 114.66 0 2 2 0 004 0 6.38 6.38 0 10-12.76 0 2 2 0 002.1 2zM65.17 61.89a2 2 0 002-2 2.33 2.33 0 114.66 0 2 2 0 004.05 0 6.38 6.38 0 10-12.76 0 2 2 0 002.05 2zM58.15 97a24.49 24.49 0 0024.47-24.45 2 2 0 10-4.05 0 20.43 20.43 0 01-40.85 0 2 2 0 10-4 0A24.49 24.49 0 0058.15 97z"
      />
    </svg>
  );
}

export default SvgHcLogo;
