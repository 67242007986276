import React from 'react';
import BatchesProcessingSummary from '../components/BatchesProcessingSummary';
import {IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonText} from '@ionic/react';
import {FadeIn} from '../components/Animations';

const BatchesInProgress = () => <IonPage>
    <IonHeader>
    <IonToolbar>
        <IonButtons slot="start">
        <IonMenuButton />
        </IonButtons>
        <IonTitle>Batches In Progress</IonTitle>
    </IonToolbar>
    </IonHeader>

    <FadeIn>
        <IonContent className="ion-padding">
            <BatchesProcessingSummary></BatchesProcessingSummary>
        </IonContent>
    </FadeIn>
</IonPage>

export default BatchesInProgress;