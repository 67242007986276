import React, { useState, useContext } from 'react';
import {IonButton, IonText, IonSpinner} from '@ionic/react';
import {useForm} from "react-hook-form";
import {TextInput, HappyInput, EmailRegex } from './FormInputs';
import KeyboardSpacer from './KeyboardSpacer';
import RollbarContext from '../context/RollbarContext';

interface SignupFormProps{
    cancelSignup: ()=>void;
    firebaseAuth: firebase.auth.Auth;
}
const SignupForm:React.FC<SignupFormProps> = ({cancelSignup, firebaseAuth}) => {
    const [creatingAccount, setCreatingAccount] = useState<boolean>(false);
    const { handleSubmit, register, errors, setValue, setError } = useForm();
    const rollbar = useContext(RollbarContext);
    const onSubmit = (values: any) => {
        setCreatingAccount(true);
        firebaseAuth.createUserWithEmailAndPassword(values.email, values.password)
            .catch(e => {
                setCreatingAccount(false);
                switch(e.code){
                    case "auth/email-already-in-use" :
                        setError("email", {type:"duplicate", message:"There is already a login with that email address, try logging in or selecting forgot password"});
                        break;
                    case "auth/invalid-email" : setError("email", {type:"invalid", message:"Invalid email address"});
                        break;
                    case "auth/weak-password" : setError("password", {type:"invalid", message:"Your password isn't strong enough, try a longer password with numbers, letters and symbols"});
                        break;
                    default : setError("password", {type:"error", message:"Sorry something went wrong, Happy Cow support has been notified"});
                        rollbar.error(e);
                }
            });
    }

    const emailValidationRules = {
        required: "Email is required",
        pattern: {
            value: EmailRegex,
            message: "That address doesn't look right, should be similar to name@provider.com"
        }
    }

    const passwordValidationRules = {
        required: "Enter a strong password",
        minLength: {
            value: 8,
            message: "Password isn't strong enough, must be at least 8 characters long"
        }
    }

    return <div className="centred-form">
        <IonText color="secondary"><h2>Sign Up</h2></IonText>
        
        <IonText color="secondary"><h4>Get all set for your Milk delivery</h4></IonText>
        <form onSubmit={handleSubmit(onSubmit)}>
            <HappyInput type="text" placeholder="Email" identifier="email" label="Email" register={register} validationRules={emailValidationRules} errors={errors}></HappyInput>
            <HappyInput type="password" placeholder="Password" identifier="password" label="Password" register={register} validationRules={passwordValidationRules} errors={errors}></HappyInput>
            <div className="ion-padding centred-column">
                <IonButton type="submit" color="secondary" className="medium" disabled={creatingAccount}>
                    {creatingAccount && <IonSpinner></IonSpinner>}
                    <span>Create</span>
                </IonButton>
                <IonButton onClick={cancelSignup} color="light" className="medium"><span>Cancel</span></IonButton>
            </div>
            <KeyboardSpacer></KeyboardSpacer>
        </form>
    </div>
}

export default SignupForm;