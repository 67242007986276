import React, { useEffect, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { isAfter } from 'date-fns';
import { BatchDetailsSubscription } from '../generated/graphql';

interface BatchHistoryChartProps {
    batchData: BatchDetailsSubscription;
}
const BatchHistoryChart: React.FC<BatchHistoryChartProps> = ({ batchData }) => {
    const [chart, setChart] = useState<any>();
    const [lastTimestamp, setLastTimestamp] = useState<string>();
    let lineColor = '#000';
    let bulletRadius = 0;
    let fillOpacity = 0;

    const setMostRecentTimestamp = () => {
        const readings = batchData.batch_by_pk.sensor_readings;
        if (!readings || readings.length < 1) return setLastTimestamp(new Date().toISOString());
        const [lastReading] = readings.slice(-1);
        setLastTimestamp(lastReading.timestamp);
    }

    useEffect(() => {
        let chart = am4core.create("chartdiv", am4charts.XYChart);
        chart.paddingRight = 20;

        let last_stage = null;
        chart.data = batchData.batch_by_pk.sensor_readings.map((reading, i) => {
            
            if (reading.stage === 'maintaining') {
                lineColor = "#27a105"
                if(last_stage !== 'maintaining' || batchData.batch_by_pk.sensor_readings[i+1]?.stage !== 'maintaining' ) {
                    bulletRadius = 0

                } else {
                    bulletRadius = 0
                }
                fillOpacity = 0.2
            } else {
                lineColor = "#22B2E7"
                bulletRadius = 0
                fillOpacity = 0
            }
            last_stage = reading.stage;
            return { date: new Date(reading.timestamp), product_temp: reading.product_temp, air_temp: reading.air_temp, stage: reading.stage, lineColor: lineColor, bulletRadius: bulletRadius, fillOpacity: fillOpacity }
        });

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.minWidth = 35;
        valueAxis.interpolationDuration = 500;
        valueAxis.rangeChangeDuration = 500;

        let productTempSeries = chart.series.push(new am4charts.LineSeries());
        productTempSeries.dataFields.dateX = "date";
        productTempSeries.dataFields.valueY = "product_temp";
        productTempSeries.interpolationDuration = 500;
        productTempSeries.defaultState.transitionDuration = 0;
        productTempSeries.tensionX = 0.8;
        productTempSeries.propertyFields.stroke = "lineColor";
        productTempSeries.propertyFields.fill = "lineColor";
        productTempSeries.name = 'Product';
        productTempSeries.tooltipText = "{valueY.value} {stage}";
        productTempSeries.propertyFields.fillOpacity = "fillOpacity";
        productTempSeries.strokeWidth = 3;

        // let bullet = productTempSeries.bullets.push(new am4charts.CircleBullet());
        // bullet.circle.propertyFields.radius = "bulletRadius";
        // bullet.circle.propertyFields.fill = "lineColor"
        // bullet.circle.strokeWidth = 0;

        let airTempSeries = chart.series.push(new am4charts.LineSeries());
        airTempSeries.dataFields.dateX = "date";
        airTempSeries.dataFields.valueY = "air_temp";
        airTempSeries.interpolationDuration = 500;
        airTempSeries.defaultState.transitionDuration = 0;
        airTempSeries.tensionX = 0.8;
        airTempSeries.stroke = am4core.color("#F5C52C"); // yellow
        airTempSeries.name = 'Air';
        airTempSeries.tooltipText = "{valueY.value}";

        // let crosscheckTempSeries = chart.series.push(new am4charts.LineSeries());
        // crosscheckTempSeries.dataFields.dateX = "date";
        // crosscheckTempSeries.dataFields.valueY = "crosscheck_temp";
        // crosscheckTempSeries.interpolationDuration = 500;
        // crosscheckTempSeries.defaultState.transitionDuration = 0;
        // crosscheckTempSeries.tensionX = 0.8;
        // crosscheckTempSeries.stroke = am4core.color("#22B2E7"); // secondary blue
        // crosscheckTempSeries.name = 'Crosscheck';
        // crosscheckTempSeries.tooltipText = "{valueY.value}";


        chart.cursor = new am4charts.XYCursor();

        let scrollbarX = new am4charts.XYChartScrollbar();
        scrollbarX.series.push(productTempSeries);
        scrollbarX.series.push(airTempSeries);

        chart.scrollbarX = scrollbarX;

        chart.legend = new am4charts.Legend();

        setChart(chart);

        setMostRecentTimestamp();

        return function cleanup() {
            if (chart) {
                chart.dispose();
            }
        }
    }, []);

    useEffect(() => {
        if(batchData.batch_by_pk.started) {
            
            if (chart) {
                const lastDate = new Date(lastTimestamp);
                const newRecords = batchData.batch_by_pk.sensor_readings.filter(reading => {
                    const readingTimestamp = new Date(reading.timestamp);
                    return isAfter(readingTimestamp, lastDate);
                });
                const newRecordObjects = newRecords.map(record => {
                    if (record.stage === 'maintaining') {
                        lineColor = "#27a105"
                        fillOpacity = 0.2
                    } else {
                        lineColor = "#22B2E7"
                        fillOpacity = 0
                    }
                    return {
                        'date': new Date(record.timestamp),
                        'name': record.timestamp,
                        'product_temp': record.product_temp,
                        'air_temp': record.air_temp,
                        'stage': record.stage,
                        'lineColor': lineColor,
                        'fillOpacity': fillOpacity
                    }
                });
                
                if (newRecordObjects[0]?.stage === 'maintaining') {
                    lineColor = "#27a105"
                    
                } else {
                    lineColor = "#22B2E7"
                    
                }
                
                chart.bulletRadius = 0;
                chart.addData(newRecordObjects);
                chart.lineColor = lineColor;
                setMostRecentTimestamp();
            } else {
                console.log('No chart');
            }
        }
    }, [batchData]);

    return <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
}

export default BatchHistoryChart;