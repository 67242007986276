import React from 'react';
import { IonGrid, IonRow, IonCol, IonRouterLink } from '@ionic/react';
import { Batch, Sensor_Reading } from '../generated/graphql';

interface BatchTileProps{
    batch: {
        __typename?: "batch";
    } & Pick<Batch, "id" | "tank_id" | "started" | "stage"> & {
        current_temp: ({
            __typename?: "sensor_reading";
        } & Pick<Sensor_Reading, "product_temp" | "air_temp" | "crosscheck_temp" | "timestamp">)[];
    }
}
const BatchTile:React.FC<BatchTileProps> = ({batch})=>{
    return <IonRouterLink routerLink={`/batch/${batch.id}`}> 
        <IonGrid className="batch-tile" >
            <IonRow>
                <IonCol>Tank {batch.tank_id}</IonCol>
                <IonCol>{batch.stage}</IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    { batch.current_temp.length > 0 && <h1>{batch.current_temp[0].product_temp}°C</h1>}
                </IonCol>
            </IonRow>
        </IonGrid>
    </IonRouterLink>
}

export default BatchTile;