import React, { useState } from 'react';
import { IonList, IonItem, IonText, IonGrid, IonRow, IonCol, IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import { format } from 'date-fns';
import { BatchDetailsSubscription } from '../generated/graphql';
import { dateAndTimeFormat } from '../shared/utils/dates';
import NoteModal from './NoteModal';


interface BatchNotesProps{
    batchData: BatchDetailsSubscription;
}
const BatchNotes:React.FC<BatchNotesProps> = ({batchData})=>{
    const batchNotes = batchData?.batch_by_pk?.batch_notes;
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    return <div className="ion-padding">
        <IonGrid>
            <IonRow>
                <IonCol><h1>Notes</h1></IonCol>
                <IonCol>
                    <IonFab horizontal="end">
                        <IonFabButton onClick={()=>setModalOpen(true)}>
                            <IonIcon icon={addOutline}></IonIcon>
                        </IonFabButton>
                    </IonFab>
                    <NoteModal batchId={batchData?.batch_by_pk?.id} isOpen={modalOpen} setIsOpen={setModalOpen}></NoteModal>
                </IonCol>
            </IonRow>
        </IonGrid>
        <IonList>
        {batchNotes && batchNotes.map((note,i) => {
            return <IonItem key={note.id}>
                <IonGrid>
                    <IonRow>
                        <IonCol>{format(new Date(note.created_at),dateAndTimeFormat)}</IonCol>
                        <IonCol>{note.text}</IonCol>
                    </IonRow>
                </IonGrid>
                <IonText></IonText>
            </IonItem>
        })}
        </IonList>
    </div>
}

export default BatchNotes;