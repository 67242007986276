import React, { useEffect, useContext, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonPage, IonContent } from '@ionic/react';
import { useSubscription } from '@apollo/react-hooks';
import { BatchDetailsSubscription } from '../generated/graphql';
import BatchHistoryTable from '../components/BatchHistoryTable';
import BatchHistoryChart from '../components/BatchHistoryChart';
import BatchDetailsList from '../components/BatchDetails';
import BatchNotes from '../components/BatchNotes';
import BatchLogSheet from '../components/BatchLogSheet';
import { batchDetailsSubscription } from '../queries/batches'
import StateContext from '../StateContext';
import { TabView, TabPanel } from 'primereact/tabview';
import "primereact/resources/primereact.min.css";  
import "primereact/resources/themes/lara-light-indigo/theme.css"; 

interface BatchDetailsProps extends RouteComponentProps<{
    id: string;
}> { }
const BatchDetails: React.FC<BatchDetailsProps> = ({ match }) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const { authStatus, signOut, user } = useContext(StateContext);

    const variables = {
        id: match.params.id
    };
    const { data, loading, error } = useSubscription<BatchDetailsSubscription>(batchDetailsSubscription, { variables });

    if (error) console.log(error);
    return <IonPage>
        <IonContent>
            {data && <div>
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} renderActiveOnly={false}>
                    <TabPanel header="Batch">
                        <BatchDetailsList data={data}></BatchDetailsList>
                        <BatchHistoryChart batchData={data}></BatchHistoryChart>
                        {/* <BatchHistoryTable data={data} batchStart={data.batch_by_pk?.started}></BatchHistoryTable> */}
                        <BatchNotes batchData={data} />
                    </TabPanel>
                    <TabPanel header="Log sheet">
                        <BatchLogSheet user={user} batchData={data}></BatchLogSheet>

                    </TabPanel>
                </TabView>
            </div>}
        </IonContent>
    </IonPage>
}

export default BatchDetails;