import React from 'react';
import {CreateAnimation} from '@ionic/react';

interface AnimationProps{
    duration?: number;
}
export const FadeIn:React.FC<AnimationProps> = ({duration, children})=>{
    return <CreateAnimation
    play={true}
    duration={duration || 1000}
    iterations={1}
    fromTo={[
        { property: 'opacity', fromValue: '0', toValue: '1' }
    ]}>{children}</CreateAnimation>
}