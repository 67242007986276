import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import React, { useState, useEffect } from "react";
import Home from '../pages/Home';
import { IonSpinner } from '@ionic/react';
import StateContext from "../../StateContext";
import AuthApolloProvider from './AuthApolloProvider';
import RoleMissing from '../../components/RoleMissing';

// Find these options in your Firebase console
const firebaseConfigParams = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};
firebase.initializeApp(firebaseConfigParams);

interface AuthProps{
  role: string;
}
const Auth:React.FC<AuthProps> = ({role = 'user', children}) => {

  const [authStatus, setAuthStatus] = useState<string>("loading");
  const [authUser, setAuthUser] = useState<any>({});
  const [authToken, setAuthToken] = useState<string>("");
  const [tokenHasuraClaim, setTokenHasuraClaim] = useState<any>(null);
  const [allowedRole, setAllowedRole] = useState<boolean>(false);

  const roleCheck = (hasuraClaim:any, role:string)=>{
    if (hasuraClaim['x-hasura-allowed-roles'].includes(role)){
      setAllowedRole(true);
    }
  }
  
  useEffect(() => {
    return firebase.auth().onAuthStateChanged(async (user:any) => {
      if (user) {
        const token = await user.getIdToken();
        // console.log(token);
        const idTokenResult = await user.getIdTokenResult();
        const hasuraClaims = idTokenResult.claims["https://hasura.io/jwt/claims"];

        if (hasuraClaims) {
          roleCheck(hasuraClaims,role);
          setTokenHasuraClaim(hasuraClaims);
          setAuthStatus("in");
          setAuthUser(user);
          setAuthToken(token);
        } else {
          // Check if refresh is required.
          const metadataRef = firebase
            .database()
            .ref("metadata/" + user.uid + "/refreshTime");

          metadataRef.on("value", async (data) => {
            if(!data.exists){
              return;
            }
            // Force refresh to pick up the latest custom claims changes.
            const token = await user.getIdToken(true);
            const idTokenResult = await user.getIdTokenResult();
            const hasuraClaims = idTokenResult.claims["https://hasura.io/jwt/claims"];
            if (hasuraClaims){
              roleCheck(hasuraClaims,role);
              setAuthStatus("in");
              setAuthUser(user);
              setAuthToken(token);
              setTokenHasuraClaim(hasuraClaims);
            }
          });
        }
      } else {
        setAuthStatus("out");
        setAuthUser(null);
        setAuthToken("");
      }
    });
  }, []);

  const signOut = async () => {
    try {
      setAuthStatus("loading");
      setAuthUser(null);
      setAuthToken("");
      await firebase.auth().signOut();
      setAuthStatus("out");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  if (tokenHasuraClaim && !allowedRole) return <RoleMissing signOut={signOut}></RoleMissing>
  if (authStatus === "loading") {
    return <div className="loading-content">
      <IonSpinner></IonSpinner>
    </div>;
  }
  if (authStatus === "in" && tokenHasuraClaim){
    return <div className="auth">
      <StateContext.Provider value={{"authStatus": authStatus, "signOut": signOut, "user": authUser}}>
        <AuthApolloProvider token={authToken} role={role}>
          { children }
        </AuthApolloProvider>
      </StateContext.Provider>
    </div>;
  }else{
    return <Home firebaseAuth={firebase.auth()}></Home>;
  }
}

export default Auth;