import React from 'react';
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/react';
import { useSubscription } from '@apollo/react-hooks';
import { tanksSummarySubscription } from '../queries/tanks';
import { TanksSummarySubscription } from '../generated/graphql';
import TankTile from '../components/TankTile';

const TanksSummary:React.FC = (props) => {
    const { data, loading, error } = useSubscription<TanksSummarySubscription>(tanksSummarySubscription);
    if (error){
        console.error(error);
        return <h4>Error</h4>;
    }
    console.log(data);
    return <IonPage>
        <IonContent>
            <IonGrid>
                <IonRow>
                    
                </IonRow>
                <IonRow>
                    {data && data.tank.sort((a,b) => a.id > b.id ? 1 : -1).map((tank, i) => {
                        return  <IonCol key={i} size="3" sizeXs="12" sizeSm="6" sizeMd="6">
                            <TankTile tank={tank}></TankTile>
                        </IonCol>
                    })}
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonPage>
}

export default TanksSummary;