import React from 'react';
import {IonText, IonIcon} from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';

interface ModalHeaderProps{
    title: string;
    close: ()=>void;
}
const ModalHeader:React.FC<ModalHeaderProps> = ({title, close})=>{
    return <div className="modal-header top-padded-modal">
        <IonText color="secondary"><h3>{title}</h3></IonText>
        <IonIcon color="primary" icon={closeCircleOutline} size="large" onClick={close}></IonIcon>
    </div>
}

export default ModalHeader;