import gql from 'graphql-tag';


export const batchProcessingOverviewSubscription = gql`
  subscription batchProcessingOverview{
    batch(
      order_by: {started: desc}
    ){
      id
      tank_id
      started
      stage
      current_temp: sensor_readings(
        limit: 1,
        order_by: {timestamp: desc}
      ){
        product_temp
        air_temp
        crosscheck_temp
        timestamp
      }
    }
  }
`;

export const batchProcessingDetailsSubscription = gql`
  subscription batchProcessingDetails{
    batch(
      where: {stage: {_nin: ["complete", "cancelled"]}},
      order_by: {started: desc}
    ){
      id
      tank_id
      started
      stage
      heating_started: batch_events(
        limit: 1,
        where: {
          type: {_eq: "batch"},
          sub_type: {_eq: "heating_started"}}
        order_by: {timestamp: desc}) {
        timestamp
      }
      maintain_started: batch_events(
        limit: 1,
        where: {
          type: {_eq: "batch"},
          sub_type: {_eq: "maintain_started"}}
        order_by: {timestamp: desc}) {
        timestamp
      }
      cooling_started: batch_events(
        limit: 1,
        where: {
          type: {_eq: "batch"},
          sub_type: {_eq: "cooling_started"}}
        order_by: {timestamp: desc}) {
        timestamp
      }
      cooling_complete: batch_events(
        limit: 1,
        where: {
          type: {_eq: "batch"},
          sub_type: {_eq: "cooling_complete"}}
        order_by: {timestamp: desc}) {
        timestamp
      }
      current_temp: sensor_readings(
        limit: 1,
        order_by: {timestamp: desc}
      ){
        product_temp
        air_temp
        crosscheck_temp
        timestamp
      }
      temp_history: sensor_readings(
        order_by: {timestamp: desc}
      ){
        product_temp
        air_temp
        crosscheck_temp
      	timestamp
    	}
    }
  }
`;
// where: {_or: {stage: {_in: ["complete", "cancelled"]},validated: {_eq: true}}},
export const completedBatchesSubscription = gql`
  subscription completedBatches{
    batch(
      where: {_or: [{stage: {_eq: "complete"}},{stage: {_eq: "cancelled"}},{validated: {_eq: true}}]},
      order_by: {started: desc}
    ){
      id
      tank_id
      started
      expires
      stage
      validated
      batch_error{
        code
        text
      }
      batch_vendors{
        vendor{
          id
          name
        }
      },
      batch_delivery_users{ 
        delivery_user{
          id
          user{
            person{
              id
              first_name
              last_name
            }
          }
        }
      }
    }
  }
`;

export const availableBatchAssigneesQuery = gql`
  query availableBatchAssignees{
    vendor{
      id
      name
    }
    delivery_user{
      id
      user{
        person{
          id
          first_name
          last_name
        }
      }
    }
  }
`;

export const assignBatchToVendorMutation = gql`
  mutation assignBatchVendor($batchId: bigint!, $vendorId: bigint!) {
    insert_batch_vendor_one(object: {batch_id: $batchId, vendor_id: $vendorId}){
      batch_id
      vendor_id
      date_assigned
    }
  }
`;

export const unassignBatchVendorMutation = gql`
  mutation unassignBatchVendor($batchId: bigint!, $vendorId: bigint!) {
    delete_batch_vendor_by_pk(batch_id: $batchId, vendor_id: $vendorId){
      batch_id
      vendor_id
    }
  }
`;

export const assignBatchToDeliveryUserMutation = gql`
  mutation assignBatchDeliveryUser($batchId: bigint!, $deliveryUserId: bigint!) {
    insert_batch_delivery_user_one(object: {batch_id: $batchId, delivery_user_id: $deliveryUserId}){
      batch_id
      delivery_user_id
      date_assigned
    }
  }
`;

export const insertBatchNoteMutation = gql`
  mutation insertBatchNote($batchId: bigint!, $text: String!) {
    insert_batch_note_one(object: {batch_id: $batchId, text: $text}){
      id
      batch_id
      text
      created_at
      updated_at
    }
  }
`;

export const insertCrossCheckMutation = gql`
mutation insertCrosscheck($crosscheck_temp: Float!, $product_temp: Float!, $batch_id: Int!) {
  insert_batch_crosscheck_one(object: {crosscheck_temp: $crosscheck_temp, product_temp: $product_temp, batch_id: $batch_id}, on_conflict: {constraint: batch_crosscheck_batch_id_key, update_columns: [crosscheck_temp, product_temp]}) {
    id
  }
}
`;

export const unassignBatchDeliveryUserMutation = gql`
  mutation unassignBatchDeliveryUser($batchId: bigint!, $deliveryUserId: bigint!) {
    delete_batch_delivery_user_by_pk(batch_id: $batchId, delivery_user_id: $deliveryUserId){
      batch_id
      delivery_user_id
    }
  }
`;

export const insertSignatureMutation = gql`
mutation insertSignature($operator_signature: String!, $verified_signature: String!, $batchId: bigint!) {
  update_batch_by_pk(pk_columns: {id: $batchId}, _set: {operator_signature: $operator_signature, verified_signature: $verified_signature}) {
    id
  }
}
`

export const updateBatchMutation = gql`
mutation UpdateBatch($batchId: bigint!, $object: batch_set_input!) {
  update_batch_by_pk(pk_columns: {id: $batchId}, _set: $object) {
    id
  }
}
`

export const batchDetailsSubscription = gql`
  subscription batchDetails($id: bigint!) {
    batch_by_pk(id: $id) {
      id
      tank_id
      started
      stage
      expires
      validated
      operator_signature
      verified_signature
      tank_asset {
        id
        asset_number
        volume
      }
      farm {
        name
      }
      batch_error{
        code
        text
      }
      user{
        id
        person{
          first_name
          last_name
        }
      }
      sensor_readings(order_by: {timestamp: asc}) {
        product_temp
        air_temp
        crosscheck_temp
        timestamp
        stage
      }
      batch_crosschecks {
        batch_id
        crosscheck_temp
        product_temp
        updated_at
      }
      batch_notes{
        id
        batch_id
        text
        created_at
        updated_at
      }
    }
  }
`;

// export const batchDetailsSubscription = gql`
//   subscription batchDetails($id: bigint!){
//     batch(
//       where: {stage: {_nin: ["complete", "cancelled"]}},
//       order_by: {started: desc}
//     ){
//       id
//       tank_id
//       started
//       stage
//       heating_started: batch_events(
//         limit: 1,
//         where: {
//           type: {_eq: "batch"},
//           sub_type: {_eq: "heating_started"}}
//         order_by: {timestamp: desc}) {
//         timestamp
//       }
//       maintain_started: batch_events(
//         limit: 1,
//         where: {
//           type: {_eq: "batch"},
//           sub_type: {_eq: "maintain_started"}}
//         order_by: {timestamp: desc}) {
//         timestamp
//       }
//       cooling_started: batch_events(
//         limit: 1,
//         where: {
//           type: {_eq: "batch"},
//           sub_type: {_eq: "cooling_started"}}
//         order_by: {timestamp: desc}) {
//         timestamp
//       }
//       cooling_complete: batch_events(
//         limit: 1,
//         where: {
//           type: {_eq: "batch"},
//           sub_type: {_eq: "cooling_complete"}}
//         order_by: {timestamp: desc}) {
//         timestamp
//       }
//       current_temp: sensor_readings(
//         limit: 1,
//         order_by: {timestamp: desc}
//       ){
//         value
//         timestamp
//       }
//       temp_history: sensor_readings(
//         order_by: {timestamp: desc}
//       ){
//         value
//         timestamp
//       }
//     }
//   }
// `;