import React from 'react';
import { IonText, IonItem, IonLabel, IonCheckbox, IonTextarea } from '@ionic/react';
import { Controller } from 'react-hook-form';

interface TextInputProps{
    type: string;
    placeholder?: string;
    identifier: string;
    label: string;
    register: any;
    validationRules?: any;
    errors: any;
    readonly?: boolean;
}
export const TextInput: React.FC<TextInputProps> = ({type, placeholder, identifier, label, register, validationRules, errors, readonly}) => {
    return (<IonItem className={errors[identifier] ? 'error-state' : ''}>
        <IonLabel position="stacked">{label}</IonLabel>
        <input type={type} placeholder={placeholder} id={'input-' + identifier} readOnly={readonly || undefined} name={identifier} ref={register(validationRules)} ></input>
        { errors[identifier] && <FormError message={errors[identifier].message}></FormError> }
    </IonItem>);
}

export const HappyInput: React.FC<TextInputProps> = ({placeholder, type, identifier, register, validationRules, errors, readonly})=>{
    return <div className="happy-input-container">
            <div className="happy-input">
            <input placeholder={placeholder} type={type} id={'input-' + identifier} readOnly={readonly || undefined} name={identifier} ref={register(validationRules)} ></input>
        </div>
        { errors[identifier] && <FormError message={errors[identifier].message}></FormError> }
    </div>
}

interface HappyCheckboxProps{
    name: string;
    control: any;
    slot: string;
    rules: any;
    errors: any;
}
export const HappyCheckbox: React.FC<HappyCheckboxProps> = ({control, name, slot, rules, errors}) => {
    
    const onRender = ({ onChange, onBlur, value, name }:any) => {
        return <IonCheckbox onIonBlur={onBlur} onIonChange={(selected:any) => onChange(selected.detail.checked)} checked={value} name={name}></IonCheckbox>
    }

    return <><Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={false}
        render={onRender}/>
        { errors[name] && <FormError message={errors[name].message}></FormError> }
    </>
}

interface TextAreaProps{
    name: string;
    control: any;
    rules: any;
    errors: any;
}
export const TextArea: React.FC<TextAreaProps> = ({control, name, rules, errors}) => {
    const onRender = ({ onChange, onBlur, value, name }:any) => {
        return <IonTextarea value={value} onIonChange={onChange}></IonTextarea>
    }

    return <><Controller
        control={control}
        name={name}
        rules={rules}
        render={onRender}
        defaultValue={''}
        />
        { errors[name] && <FormError message={errors[name].message}></FormError> }
    </>
}

interface FormErrorProps{
    message: string
}
export const FormError: React.FC<FormErrorProps> = ({message}) => {
    return (
        <IonText className="error-message" color="danger">{message}</IonText>
    );
}

export const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;