import React from 'react';
import { IonModal, IonButton, IonContent, IonItem, IonLabel } from '@ionic/react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { TextArea } from '../shared/components/FormInputs';
import { insertBatchNoteMutation } from '../queries/batches';
import { InsertBatchNoteMutation } from '../generated/graphql';

interface NoteModalProps{
    batchId: number;
    isOpen: boolean;
    setIsOpen: (isOpen:boolean)=>void
}
const NoteModal:React.FC<NoteModalProps> = ({batchId, isOpen, setIsOpen})=>{

    const { handleSubmit, control, errors} = useForm();
    const [insertNote] = useMutation<InsertBatchNoteMutation>(insertBatchNoteMutation);

    const onSubmit = async (values) => {
        try{
            const variables = {
                batchId: batchId,
                text: values.text
            }
            const result = await insertNote({variables});
            console.log(result);
            setIsOpen(false);
        }catch(e){
            console.log(e);
            alert('Error while saving, details in console');
        }
    }

    return <IonModal isOpen={isOpen} onDidDismiss={()=>setIsOpen(false)}>
        <IonContent className="ion-padding">
            <h1>Add Batch Note</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <IonItem>
                    <IonLabel>Note:</IonLabel>
                    <TextArea name="text" control={control} rules={{required: 'Required'}} errors={errors}></TextArea>
                </IonItem>
                <IonButton type="submit">Save</IonButton>
                <IonButton onClick={()=>setIsOpen(false)}>Cancel</IonButton>
            </form>
        </IonContent>
    </IonModal>
};

export default NoteModal;