import React from 'react';

interface IInitialState{
    authStatus: string,
    signOut: ()=>void,
    user: any
}

const StateContext = React.createContext<IInitialState>({
    authStatus: "",
    signOut: ()=>null,
    user: null
});

export default StateContext;