import gql from 'graphql-tag';

export const getPrestartChecksQuery = gql`
query getPrestartChecks {
    prestart_checks {
      id
      text
      type
    }
  }
  
  
`;

export const insertBatchPrestartMutation = gql`
    mutation insertBatchPrestart(
        $wash_hands: Boolean!,
        $hub_surfaces_clean: Boolean!,
        $hub_doors_clean: Boolean!,
        $gas_bottle_level: Boolean!,
        $ice_level_temp: Boolean!,
        $raw_milk_line: Boolean!,
        $insert_milk_filter: Boolean!,
        $notes: String!,
        $tank_id: bigint!
    ){
    insert_batch_prestart_checklist(objects: {
        wash_hands: $wash_hands,
        hub_surfaces_clean: $hub_surfaces_clean,
        hub_doors_clean: $hub_doors_clean,
        gas_bottle_level: $gas_bottle_level, 
        ice_level_temp: $ice_level_temp,
        raw_milk_line: $raw_milk_line,
        insert_milk_filter: $insert_milk_filter,
        notes: $notes,
        tank_id: $tank_id}) {
        returning {
            id
        }
    }
  }
`;