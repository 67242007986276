import {Person} from '../../generated/graphql';

export const getNameFromPerson = (person:Pick<Person, 'first_name' | 'last_name'>)=>{
    if (!person) return '';
    let nameArray = [];
    if (person.first_name) nameArray.push(person.first_name);
    if (person.last_name) nameArray.push(person.last_name);

    return nameArray.join(' ');
}

export const getFirstNameFromName = (name:string)=>{
    const nameParts = name.split(' ');
    return nameParts?.[0];
}