import React, { useState, useContext } from 'react';
import {IonButton, IonText, IonRouterLink, IonModal, IonSpinner} from '@ionic/react';
import SignupForm from './SignupForm';
import {useForm} from "react-hook-form";
import { TextInput, HappyInput, EmailRegex } from './FormInputs';
import KeyboardSpacer from './KeyboardSpacer';
import ForgotPassword from './ForgotPassword';
import RollbarContext from '../context/RollbarContext';
import Rollbar from 'rollbar';

interface LoginFormProps{
    firebaseAuth: firebase.auth.Auth;
    cancelLogin: ()=>void;
}
const LoginForm:React.FC<LoginFormProps> = ({firebaseAuth, cancelLogin}) => {
    const { handleSubmit, register, errors, setValue, setError } = useForm();
    const [ forgotOpen, setForgotOpen] = useState<boolean>(false);
    const [ loggingIn, setLoggingIn ] = useState<boolean>(false);
    const rollbar = useContext(RollbarContext);

    const onSubmit = (values:any)=>{
        setLoggingIn(true);
        firebaseAuth.signInWithEmailAndPassword(values.email,values.password)
            .catch(e => {
                setLoggingIn(false);
                switch (e.code){
                    case 'auth/user-not-found' : setError("email", {type:"notFound", message:"There is no account matching that email"});
                        break;
                    case 'auth/wrong-password' : setError("password", {type:"invalid", message:"Password is incorrect"});
                        break;
                    default: setError("password", {type:"other", message:"There was a problem logging you into your account, Happy Cow support has been notified"});
                        console.error(e);
                        rollbar.error(e);
                }
            });
    }

    const emailValidationRules = {
        required: "Email is required",
        pattern: {
            value: EmailRegex,
            message: "That address doesn't look right, should be similar to name@provider.com"
        }
    }

    const passwordValidationRules = {
        required: "Please enter your password"
    }

    return <div className="ion-padding">
        {!forgotOpen && <div className="centred-form">
            <img className="logo-img" src="./assets/HappyCowLogo.png" />
            <IonText color="secondary"><h2>Log In</h2></IonText>
            <form id="form-login" onSubmit={handleSubmit(onSubmit)}>
                <HappyInput type="email" placeholder="Email" identifier="email" label="Email" register={register} validationRules={emailValidationRules} errors={errors}></HappyInput>
                <HappyInput type="password" placeholder="Password" identifier="password" label="Password" register={register} validationRules={passwordValidationRules} errors={errors}></HappyInput>
                <div className="ion-padding centred-column">
                    <IonButton type="submit" className="medium" color="secondary" disabled={loggingIn}>
                        { loggingIn && <IonSpinner color="light"></IonSpinner>}
                        <span>Log Me In</span></IonButton>
                    <IonButton color="light" className="medium" onClick={()=>cancelLogin()}><span>Cancel</span></IonButton>
                    <IonText color="secondary" onClick={()=>setForgotOpen(true)}><span>Forgot Password?</span></IonText>
                </div>
                <KeyboardSpacer></KeyboardSpacer>
            </form>
        </div>}
        { forgotOpen && <ForgotPassword firebaseAuth={firebaseAuth} close={()=>setForgotOpen(false)}></ForgotPassword>}
    </div>
}

export default LoginForm;