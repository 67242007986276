import React from 'react';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { getMainDefinition } from 'apollo-utilities';
import { setContext } from 'apollo-link-context';
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { ApolloProvider } from '@apollo/react-hooks';

interface AuthApolloProviderProps{
    token: string;
    role: string;
}
const AuthApolloProvider:React.FC<AuthApolloProviderProps> = ({token, role, children}) => {
    const headers = { 
        Authorization: `Bearer ${token}`,
        'x-hasura-role': role
    };

    const httpLink = new HttpLink({
        uri: process.env.REACT_APP_HASURA_HTTP_ENDPOINT,
        headers
    });

    const wsLink = new WebSocketLink({
        uri: process.env.REACT_APP_HASURA_WEBSOCKET_ENDPOINT || '',
        options: {
        reconnect: true,
        connectionParams: {
            headers
        }
        }
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            for (let err of graphQLErrors) {
              console.log(err);
            }
          }
          if (networkError) {
            console.log("[Network error]");
            console.log(networkError);
            //this is the best we can do
            //nothing in the payload that's more solid like an error code that we can check
            if (networkError?.message.includes("JWTExpired")){
                console.log('JWT Expired, reloading page');
                window.location.reload(); // this could be improved but it will still improve UX for the time being
            }
          }
    });

    const link = split(
        ({ query }) => {
        const { kind, operation } = getMainDefinition(query) as any;
        return kind === "OperationDefinition" && operation === "subscription";
        },
        wsLink,
        httpLink
    );

    const composedLink = ApolloLink.from([errorLink, link]);

    const client = new ApolloClient({
        link: composedLink,
        cache: new InMemoryCache()
    });
    
    return <ApolloProvider client={client}>
        {children} 
    </ApolloProvider>
};

export default AuthApolloProvider;