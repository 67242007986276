import Menu from './components/Menu';
import BatchesInProgress from './pages/BatchesInProgress';
import BatchesCompleted from './pages/BatchesCompleted';
import BatchDetails from './pages/BatchDetails';
import TanksSummary from './pages/TanksSummary';
import React from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './App.scss';

const App: React.FC = () => {

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/tanks" component={TanksSummary} exact />
            <Route path="/inprogress" component={BatchesInProgress} exact />
            <Route path="/batch/:id" component={BatchDetails} exact />
            <Route path="/batches" component={BatchesCompleted} exact />
            <Route path="/" render={() => <Redirect to="/tanks" />} exact={true} />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
