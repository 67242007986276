import React from 'react';

// a component to add space to the bottom of forms so the keyboard on mobile devices doesn't block the last inputs or submit button

const spaceAmount = '200';

const KeyboardSpacer:React.FC = () => {
    return <div style={{'height': spaceAmount+'px'}}></div>
}

export default KeyboardSpacer;