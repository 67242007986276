import React, { Fragment } from 'react';
import { IonList } from '@ionic/react';
import { Batch } from '../generated/graphql';
import BatchListItem from './BatchListItem';

interface BatchesListProps{
    batches: Partial<Batch>[]
}
const BatchesList:React.FC<BatchesListProps> = ({batches})=>{
    return <Fragment>
        <IonList>
            { batches.map((batch,i) => <BatchListItem key={i} batch={batch}></BatchListItem>)}
        </IonList>
    </Fragment>
}

export default BatchesList;