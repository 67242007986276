import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox';
import { useMutation } from '@apollo/react-hooks';
import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { BatchDetailsSubscription } from '../generated/graphql';
import { insertSignatureMutation } from '../queries/batches';
import { updateBatchMutation } from '../queries/batches';
import { UpdateBatchMutation } from '../generated/graphql';
import { UpdateBatchMutationVariables } from '../generated/graphql';
import { InsertSignatureMutation } from '../generated/graphql';
import { format } from 'date-fns'
import { getNameFromPerson } from '../shared/utils/person';
interface BatchLogSheetProps {
    batchData: BatchDetailsSubscription
    user?: any
}
interface FieldProps {
    field: {
        "header": string | undefined,
        "label": string,
        "value": string | undefined,
        "rules": any | undefined,
        "width": string,
        "height": string | undefined,
        "type"?: string,
        "editable": boolean,
        
    }
    fieldName: string,
    sign?: boolean,
    editable?: boolean,
    "ref"?: string,
}

const BatchLogSheet: React.FC<BatchLogSheetProps> = (props) => {

    const assetFieldRef = useRef(null)

    const [insertSignature] = useMutation<InsertSignatureMutation>(insertSignatureMutation)
    const [updateBatch, { }] = useMutation<UpdateBatchMutation>(updateBatchMutation)
    const sensor_readings = [...props.batchData.batch_by_pk.sensor_readings]

    const maintaining_readings = sensor_readings.filter(reading => reading.stage == 'maintaining')
    sensor_readings.sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp))

    let maintain_readings = []
    let _hitMaintaining = false
    for (let reading of sensor_readings) {
        console.log(reading.stage)
        if(reading.stage == 'maintaining') {
            _hitMaintaining = true
            maintain_readings.push(reading)
            continue
        }
        if(reading.stage != 'maintaining' && _hitMaintaining) {
            break
        }
    }

    console.log(maintain_readings)
    maintain_readings.reverse()
    const firstMaintainReading = maintain_readings[0]
    const lastMaintainReading = maintain_readings[maintain_readings.length - 1]


    const crosscheck_temp = props.batchData.batch_by_pk.batch_crosschecks[0]?.crosscheck_temp
    const actual_temp = props.batchData.batch_by_pk.batch_crosschecks[0]?.product_temp

    const pasteurisationChecksFields = {
        "header-pasteurisation-checks": {
            "type": "header",
            "header": "PASTEURISATION CHECKS (CCP)"
        },
        "product-temp": {
            "label": "Product Temperature",
            "value": firstMaintainReading.product_temp,
            "rules": null,
            "width": '100%',

        },
        "headspace-temp": {
            "label": "Headspace Temperature",
            "value": firstMaintainReading.air_temp,
            "rules": null,
            "width": '100%'
        },
        "crosscheck": {
            "label": "Product Cross-check",
            "value": crosscheck_temp,
            "rules": null,
            "width": '100%'
        },
        "actual": {
            "label": "Product Actual",
            "value": actual_temp,
            "rules": null,
            "width": '100%'
        },
        "start": {
            "label": "Pasteurisation Start Time",
            "value": "",
            "rules": null,
            "width": '100%'
        },
        "stop": {
            "label": "Pasteurisation Stop Time",
            "value": lastMaintainReading.timestamp,
            "rules": null,
            "width": '100%'
        },
    }

    const [tankAssetValue, setTankAssetValue] = useState<String | Boolean>(String(props.batchData.batch_by_pk?.tank_asset?.asset_number) || "")
    const [fieldValues, setFieldValues] = useState({
        "date": format(Date.parse(props.batchData.batch_by_pk.started), "dd/MM/yyyy"),
        "tank": props.batchData.batch_by_pk.tank_id,
        "batch": props.batchData.batch_by_pk.id,
        "milkdate": format(Date.parse(props.batchData.batch_by_pk.started), "dd/MM/yyyy"),
        "milkvolume": String(props.batchData.batch_by_pk?.tank_asset?.volume),
        "product-temp": firstMaintainReading.product_temp,
        "headspace-temp": firstMaintainReading.air_temp,
        "crosscheck": crosscheck_temp,
        "actual": actual_temp,
        "start": format(Date.parse(firstMaintainReading.timestamp), "dd/MM/yyyy HH:mm:ss"),
        "stop": format(Date.parse(lastMaintainReading.timestamp), "dd/MM/yyyy HH:mm:ss"),
        "signature": props.batchData.batch_by_pk.operator_signature || "",
        "verified": props.batchData.batch_by_pk.verified_signature || "",
        "cip-complete": true,
        "good-repair": true,
        "inline-filter": true,
        "inlet": true,
        "tankasset": props.batchData.batch_by_pk?.tank_asset?.asset_number || ""

    })
    const [readyToSubmit, setReadyToSubmit] = useState(false)

    const pasteurisationDetailsFields = {
        "header-pasteurisation-details": {
            "type": "header",
            "header": "PASTEURISATION DETAILS"
        },
        "date": {
            "label": "Paseurisation Date",
            "value": fieldValues['date'],
            "rules": {
                "required": true,
                "max": null,
                "min": null,
                "regex": ""
            },
            "width": '50%',
            "editable": false,
        },
        "tank": {
            "label": "Process Bay",
            "value": fieldValues["tank"],
            "rules": null,
            "width": '50%',
            "editable": false,
        },
        "tankasset": {
            "label": "Tank ID",
            "value": fieldValues['tankasset'],
            "rules": null,
            "width": '50%',
            "editable": true,
            "ref": assetFieldRef
        },
        "batch": {
            "label": "Finished Product Batch Number",
            "value": fieldValues['batch'],
            "rules": null,
            "width": '100%',
            "editable": false,
        },
        "milkdate": {
            "label": "Milking Date",
            "value": "",
            "rules": null,
            "width": '50%',
            "editable": false,
        },
        "milkvolume": {
            "label": "Milk Volume (L)",
            "value": fieldValues['milkvolume'],
            "rules": null,
            "width": '50%',
            "editable": false,
        },

    }

    const pasteurisationSignatureFields = {
        "signature": {
            "label": "Operator Signature",
            "rules": null,
            "width": '50%',
            "height": 50,
            "type": "signature"
        },
        "verified": {
            "label": "Verified by",
            "rules": null,
            "width": '50%',
            "height": 50
        }
    }

    const pasteurisationPreStartFields = {
        "cip-complete": {
            "label": "Pasteuriser CIP completed",
            "width": "55%",
            "rules": null,
            "height": 40,
            "type": "checkbox"
        },
        "good-repair": {
            "label": "Pasteuriser in good repair",
            "width": "55%",
            "rules": null,
            "height": 40,
            "type": "checkbox"
        },
        "inline-filter": {
            "label": "Inline >200 um filter (no holes, distortion or damage)",
            "width": "55%",
            "rules": null,
            "height": 40,
            "type": "checkbox"
        },
        "inlet": {
            "label": "Raw milk inlet to tank removed and blanked off",
            "width": "55%",
            "rules": null,
            "height": 40,
            "type": "checkbox"
        }
    }

    const handleChange = (field: string, value: string | boolean) => {
        if (field === "tankasset") {
            setTankAssetValue(value)
            const _object = {
                _tank_asset: value
            }
            const variables = { batchId: fieldValues['batch'], object: _object }
            
            updateBatch({ variables }).then(() => {
                alert('Tank ID saved')
                
            }).catch(e => {
                assetFieldRef.current.focus()
            })
        } else {
            let _prev = { ...fieldValues }
            _prev[field] = value
            setFieldValues(_prev)
            
        }
        
        }

        const signHere = (field: string) => {
            if (fieldValues[field]) {
                handleChange(field, "")
            } else {
                if (props.user == null) {
                    alert("Have you set up your profile?")

                }
                handleChange(field, props.user.email)
                // console.log(props.user.email)

            }

        }

        const handleSubmit = () => {
           
        }
        
        useEffect(() => {
            if(!readyToSubmit) {
                setReadyToSubmit(true)
                return () => {}
            }
            const vars = { operator_signature: fieldValues['signature'], verified_signature: fieldValues['verified'], batchId: fieldValues['batch'] }
            insertSignature({ variables: vars }).then(() => {
                alert('Saved')
            })
                .catch(e => { })

        },[fieldValues])

        const Field: React.FC<FieldProps> = (props) => {
            let fieldValue = fieldValues[props.fieldName]
            let fieldRef = null
            if (props.field.header) {

                return (<div style={{ height: 50, textAlign: 'center', display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center', fontWeight: 'bold' }}>{props.field.header}</div>)
            }
            if (props.field.type == "checkbox") {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', width: props.field.width, justifyContent: 'space-between', height: 30, alignItems: 'center', paddingLeft: 5 }}>

                        <label style={{ alignSelf: 'center', fontSize: 14 }}>{props.field.label}</label>
                        <Checkbox disabled onChange={(e) => handleChange(props.fieldName, e.checked)} checked={fieldValues[props.fieldName]} />
                    </div>
                )
            }

            if(props.fieldName === "tankasset") {
                fieldValue = tankAssetValue
                fieldRef = assetFieldRef
            }

            return (
                <div style={{ display: 'flex', flexDirection: 'row', height: props.field.height ? props.field.height : '30px', width: props.field.width, justifyContent: 'space-between', paddingLeft: 5, borderBottomStyle: 'none', borderBottomWidth: 0.2 }}>
                    <label style={{ alignSelf: 'center', fontSize: 14 }}>{props.field.label}</label>
                    <InputText ref={fieldRef} disabled={!props.editable} onClick={props.sign ? () => signHere(props.fieldName) : () => { }} onChange={(e) => handleChange(props.fieldName, e.target.value)} id={props.fieldName} name={props.fieldName} value={fieldValue} style={{ cursor: props.sign ? 'pointer' : '', fontSize: 14, width: `${parseInt(props.field.width.replace('%', '')) / 1.5}%`, borderStyle: 'none', borderBottomStyle: 'solid', borderBottomWidth: 0.2 }} />
                </div>
            )
        }

        const DETAILFIELDS = Object.keys(pasteurisationDetailsFields).map((field) => {
            return <Field editable={pasteurisationDetailsFields[field].editable} key={field} field={pasteurisationDetailsFields[field]} fieldName={field} />
        })

        const CHECKFIELDS = Object.keys(pasteurisationChecksFields).map((field) => {
            return <Field key={field} field={pasteurisationChecksFields[field]} fieldName={field} />

        })


        const SIGNATUREFIELDS = Object.keys(pasteurisationSignatureFields).map((field) => {
            return <Field editable sign={true} key={field} field={pasteurisationSignatureFields[field]} fieldName={field} />

        })

        const PRESTARTFIELDS = Object.keys(pasteurisationPreStartFields).map((field) => {
            return <Field key={field} field={pasteurisationPreStartFields[field]} fieldName={field} />

        })



        return (
            <div>
                <div style={
                    {
                        backgroundColor: '#eeeeee',
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        flexWrap: 'wrap',
                        margin: 15,
                        paddingBottom: 30,
                        borderWidth: .02, borderColor: '#000', borderStyle: 'solid'

                    }
                }>

                    {DETAILFIELDS}
                    {PRESTARTFIELDS}
                    {CHECKFIELDS}

                    <div style={{ backgroundColor: '#fff', padding: 10, fontStyle: 'italic', width: '100%', textAlign: 'center', borderBottomStyle: 'solid', borderBottomWidth: 0.2 }}>
                        Check trend to ensure all readings meet minimum temperature requirements for at least 2 minutes
                    </div>

                    {SIGNATUREFIELDS}

                </div>
                <Button onClick={handleSubmit} label='UPDATE' />
            </div>
        )
    }

    export default BatchLogSheet
