import React, { Fragment } from 'react';
import {IonFab, IonFabButton, IonIcon, IonGrid, IonRow, IonCol} from '@ionic/react';
import {addOutline} from 'ionicons/icons';
import { useSubscription } from '@apollo/react-hooks';
import BatchCard from './BatchCard';
import BatchTile from './BatchTile';
import { batchProcessingOverviewSubscription } from '../queries/batches';
import { BatchProcessingOverviewSubscription } from '../generated/graphql';

const BatchesProcessingSummary = () => {
    const { loading, error, data } = useSubscription<BatchProcessingOverviewSubscription>(batchProcessingOverviewSubscription);

    if (loading) return <p>Loading...</p>;
    if (error){
        console.log(error);
        return <p>Error :(</p>
    }
    if (data.batch.length === 0){
        return <p>No batches currently processing</p>
    }
    
    return <IonGrid>
        <IonRow>
            {data.batch.map((batch,i) => (
                <IonCol size="3" sizeXs="12" sizeSm="4" sizeMd="4">
                    <BatchTile batch={batch} key={i}></BatchTile>
                </IonCol>
            ))}

            <IonFab vertical="bottom" horizontal="end" slot="fixed">
                <IonFabButton>
                    <IonIcon icon={addOutline}></IonIcon>
                </IonFabButton>
            </IonFab>
        </IonRow>
    </IonGrid>;
  };

export default BatchesProcessingSummary;