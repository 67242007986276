import React from 'react';
import { IonGrid, IonRow, IonCol, IonText, IonIcon } from '@ionic/react';
import { BatchDetailsSubscription } from '../generated/graphql';
import { dateAndTimeFormat, shortDateFormat } from '../shared/utils/dates';
import { format } from 'date-fns';
import { getNameFromPerson } from '../shared/utils/person';
import {checkmarkOutline, closeOutline} from 'ionicons/icons';
interface BatchDetailsProps{
    data: BatchDetailsSubscription;
}
const BatchDetails:React.FC<BatchDetailsProps> = ({data})=>{
    const farmerPerson = data?.batch_by_pk?.user?.person;
    let farmerName = getNameFromPerson(farmerPerson);
    return <IonGrid>
        <IonRow>
            <IonCol>Batch: </IonCol>
            <IonCol>{data?.batch_by_pk?.id}</IonCol>
        </IonRow>
        <IonRow>
            <IonCol>Tank: </IonCol>
            <IonCol>{data?.batch_by_pk?.tank_id}</IonCol>
        </IonRow>
        <IonRow>
            <IonCol>Farmer Name: </IonCol>
            <IonCol>{data?.batch_by_pk?.farm.name}</IonCol>
        </IonRow>
        <IonRow>
            <IonCol>Started: </IonCol>
            <IonCol>{format(new Date(data?.batch_by_pk?.started), dateAndTimeFormat)}</IonCol>
        </IonRow>
        <IonRow>
            <IonCol>Expires: </IonCol>
            <IonCol>{format(new Date(data?.batch_by_pk?.expires), shortDateFormat)}</IonCol>
        </IonRow>
        <IonRow>
            <IonCol>Crosscheck: </IonCol>
            <IonCol>{data?.batch_by_pk?.batch_crosschecks[0] !== undefined ? 'product: '+data?.batch_by_pk?.batch_crosschecks[0]?.product_temp+' / crosscheck: '+data?.batch_by_pk?.batch_crosschecks[0]?.crosscheck_temp : 'not available'}</IonCol>
        </IonRow>
        <IonRow>
            <IonCol>Status: </IonCol>
            <IonCol>{data?.batch_by_pk?.batch_error && data?.batch_by_pk?.batch_error.text+' ('+data?.batch_by_pk?.batch_error.code+')'}</IonCol>
        </IonRow>
        <IonRow>
            <IonCol>Validated: </IonCol>
            <IonCol>{data?.batch_by_pk?.validated ? <IonIcon icon={checkmarkOutline} /> : <IonIcon icon={closeOutline} />}</IonCol>
        </IonRow>
    </IonGrid>
}

export default BatchDetails;