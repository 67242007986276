import React, { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { IonGrid, IonRow, IonCol, IonText, IonCheckbox, IonItem, IonList, IonLabel, IonButton } from '@ionic/react';
import { HappyCheckbox, TextArea } from '../shared/components/FormInputs';
import { getPrestartChecksQuery, insertBatchPrestartMutation } from '../queries/checklist';
import { GetPrestartChecksQuery, InsertBatchPrestartMutation } from '../generated/graphql';
import { useQuery } from '@apollo/react-hooks';
import { Tank, Batch, Sensor_Reading, Maybe } from '../generated/graphql';
interface ChecklistItem {
    key: string;
    label: string;


}



// const checklistItems:ChecklistItem[] = [
//     { 
//         key: 'wash_hands',
//         label: 'Wash hands'
//     },
//     {
//        key: 'hub_surfaces_clean',
//        label: 'Check Hub surfaces are clean'
//     },
//     {
//         key: 'hub_doors_clean',
//         label: 'Check Hub doors and seals are clean'
//      },
//      {
//         key: 'gas_bottle_level',
//         label: 'Check gas bottle levels'
//      },
//      {
//         key: 'ice_level_temp',
//         label: 'Check ice bank water level and temperature is below 1 degree'
//      },
//      {
//         key: 'raw_milk_line',
//         label: 'Inspect raw milk line and filter housing'
//      },
//      {
//         key: 'insert_milk_filter',
//         label: 'Insert new milk filter'
//      },
// ];

//'hub_doors_clean','gas_bottle_level','ice_level_temp','raw_milk_line','insert_milk_filter'];

interface BatchStartChecklistProps {
    tankId: number;
    tank: { __typename?: 'tank' }
    & Pick<Tank, 'id' | 'last_seen'>
    & {
        current_batch?: Maybe<(
            { __typename?: 'batch' }
            & Pick<Batch, 'id' | 'stage'>
            & {
                current_temp: Array<(
                    { __typename?: 'sensor_reading' }
                    & Pick<Sensor_Reading, 'product_temp' | 'air_temp' | 'crosscheck_temp' | 'timestamp'>
                )>
            }
        )>
    }
    onStartBatch: any;
    onCancel: () => void;
}
const BatchStartChecklist: React.FC<BatchStartChecklistProps> = ({ tank, tankId, onStartBatch, onCancel }) => {
    const { handleSubmit, control, errors } = useForm();
    const [insertBatchPrestart] = useMutation<InsertBatchPrestartMutation>(insertBatchPrestartMutation);
    const onSubmit = async (values) => {
        try {
            // const variables = { tank_id: tankId, ...values };
            // await insertBatchPrestart({ variables });
            onStartBatch(tank);
        } catch (e) {
            console.log(e);
            alert('Error saving checklist/starting batch');
        }
    }

    let checklistItems: ChecklistItem[] = []

    const { data: checklistData } = useQuery<GetPrestartChecksQuery>(getPrestartChecksQuery)

    useEffect(() => {
        if(checklistData) {

            for (let item of checklistData.prestart_checks) {
                checklistItems.push({
                    key: String(item.id),
                    label: item.text
                })
            }


        }
    }, [checklistData])

    if(!checklistData) {
        return <div>Loading..</div>
    }
    return <React.Fragment>
        <IonText color="secondary"><h3>Initial Checklist</h3></IonText>
        <form onSubmit={handleSubmit(onSubmit)}>
            <IonList>
                {checklistData.prestart_checks.map((item, i) => <IonItem key={i}>
                    <IonLabel>{item.text}</IonLabel>
                    <HappyCheckbox name={String(item.id)} control={control} slot="end" rules={{ required: 'Required' }} errors={errors}></HappyCheckbox>
                </IonItem>)}
                {/* <IonItem>
                    <IonLabel>Notes</IonLabel>
                    <TextArea name="notes" control={control} rules={{}} errors={errors}></TextArea>
                </IonItem> */}
            </IonList>
            <IonButton type="submit">Start</IonButton>
        </form>
    </React.Fragment>
}

export default BatchStartChecklist;