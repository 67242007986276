import React, { useState } from 'react';
import {useForm} from "react-hook-form";
import { IonText, IonButton } from '@ionic/react';
import { HappyInput, EmailRegex } from './FormInputs';
import ModalHeader from './ModalHeader';

const emailValidationRules = {
    required: "Email is required",
    pattern: {
        value: EmailRegex,
        message: "That address doesn't look right, should be similar to name@provider.com"
    }
}

interface ForgotPasswordProps{
    firebaseAuth: firebase.auth.Auth;
    close: ()=>void;
}
const ForgotPassword:React.FC<ForgotPasswordProps> = ({firebaseAuth, close})=>{
    const { handleSubmit, register, errors } = useForm();
    const [ emailSent, setEmailSent ] = useState<boolean>(false);

    const onSubmit = async (values:any)=>{
        await firebaseAuth.sendPasswordResetEmail(values.email);
        setEmailSent(true);
    }

    return <div className="ion-padding">
        <ModalHeader title="Forgot Password" close={close}></ModalHeader>    
        {!emailSent && <div className="centred-form">
            <IonText color="primary" className="left-text"><span>Enter the email address you signed up with and we will send you instructions to reset your password</span></IonText>
            <form id="form-forgot" className="ion-padding" onSubmit={handleSubmit(onSubmit)}>
                <HappyInput type="text" placeholder="Email" identifier="email" label="Email" register={register} validationRules={emailValidationRules} errors={errors}></HappyInput>
                <IonButton type="submit" className="medium" color="secondary"><span>Reset Password</span></IonButton>
            </form>
        </div>}
        {emailSent && <div className="centred-form">
            <IonText color="primary"><span>We have sent the instructions to reset your password to your specified email address</span></IonText>
            <IonButton color="secondary" className="medium" onClick={()=>close()}><span>Done</span></IonButton>
        </div>
        }
    </div>
}

export default ForgotPassword;