import React from 'react';
import {IonApp, IonContent, IonText, IonButton} from '@ionic/react';

interface RoleMissingProps{
    signOut: ()=>void;
}
const RoleMissing:React.FC<RoleMissingProps> = ({signOut})=>{
    return <IonApp>
        <IonContent className="ion-padding">
            <IonText color="primary"><h1>Nearly there...</h1></IonText>
            <IonText><h4>Looks like you're not currently assigned to a farm, please contact Happy Cow to get this sorted out.</h4></IonText>
            <IonText color="primary"><h4>ph: 03xxxxxxx</h4></IonText>
            <IonText color="primary"><h4>e: suppliers@happycowmilk.co.nz</h4></IonText>
            <div className="ion-padding">
                <IonButton onClick={()=>{signOut()}}>Logout</IonButton>
            </div>
        </IonContent>
    </IonApp>
}

export default RoleMissing;