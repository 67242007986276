import gql from 'graphql-tag';

export const tanksSummarySubscription = gql`
    subscription tanksSummary{
        tank{
            id
            last_seen
            current_batch{
                id
                stage
                current_temp: sensor_readings(
                    limit: 1,
                    order_by: {timestamp: desc}
                ){
                    product_temp
                    air_temp
                    crosscheck_temp
                    timestamp
                }
            }
        }
    }
`;

export const startBatchMutation = gql`
    mutation startBatch($tankId: bigint!, $userId: String){
        insert_pasteuriser_command_one(object: {
            tank_id: $tankId
            user_id: $userId
            command: "COMMAND_START"
        }, on_conflict:{
            constraint: pasteuriser_command_tank_id_key
            update_columns: command
        }){
            id
        }
    }
`;

export const stopBatchMutation = gql`
    mutation stopBatch($tankId: bigint!, $userId: String){
        insert_pasteuriser_command_one(object: {
            tank_id: $tankId
            user_id: $userId
            command: "COMMAND_STOP"
        }, on_conflict:{
            constraint: pasteuriser_command_tank_id_key
            update_columns: command
        }){
            id
        }
    }
`;