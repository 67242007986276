import React from 'react';
import {useSubscription} from '@apollo/react-hooks';
import {IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent} from '@ionic/react';
import { FadeIn } from '../components/Animations';
import BatchesList from '../components/BatchesList';
import {completedBatchesSubscription} from '../queries/batches';
import { CompletedBatchesSubscription } from '../generated/graphql';

interface BatchesCompletedProps{

}
const BatchesCompleted:React.FC<BatchesCompletedProps> = (props)=>{
    const { loading, error, data } = useSubscription<CompletedBatchesSubscription>(completedBatchesSubscription);
    if (loading) return <h4>loading...</h4>;
    if (error){
        return <h4>An error occurred</h4>;
    }
    return <IonPage>
        <IonHeader>
        <IonToolbar>
            <IonButtons slot="start">
                <IonMenuButton />
            </IonButtons>
            <IonTitle>Completed Batches</IonTitle>
        </IonToolbar>
        </IonHeader>

        <FadeIn>
            <IonContent className="ion-padding">
                <BatchesList batches={data.batch}></BatchesList>
            </IonContent>
        </FadeIn>
    </IonPage>
}

export default BatchesCompleted;